import config from '../config';
export default {
  lang: `En`,
  begin: `Begin`,
  enterPGN: `Enter your case number`,
  enterPhone: `Enter your phone number`,
  samePhone: `Do you still have the number: `,
  enterDigits: `Enter last ${config.digits} digits`,
  enterOTP: `Enter the ${config.otp} digits that you received in SMS`,
  submit: `Submit`,
  stillHaveCard: `Do you still have the card?`,
  submitAnotherOTP: `Send another code`,

  stillHavePin: `Do you still have the PIN?`,
  yes: `Yes`,
  no: `No`,

  intro: `UNHCR is launching a remote Card validation exercise for families receiving cash assistance. To continue receiving cash assistance, you must successfully validate your Red Card remotely by providing the following information.`,

  error: `Error Occured`,
  nvn: `No Validation Needed`,
  cd: `Correct Digits`,
  wd: `Wrong Digits`,
  ta: `Too Many Attempts`,
  id: `Invalid Digits`,
  cv: `Card Already Validated`,
  wp: `Wrong Case Number`,
  or: `OTP Request`,
  ip: `Invalid Phone Number`,
  wo: `Wrong OTP`,

  ipDesc: `You have entered an invalid phone number`,
  woDesc: `You have entered a wrong code`,
  orDesc: `You will receive an SMS containing ${config.otp} digits, enter them in the text field showing`,
  wpDesc: `You have entered a wrong case number`,
  cvDesc: `Your card has already been validated`,
  idDesc: `Enter exactly ${config.digits} Digits`,
  nvnDesc: `You either have done your verification or you are not yet in the verification list`,
  cdDesc: `You have entered correct digits, continue`,
  wdDesc: `You have entered wrong digits, please make sure you are entering the last digits from left to right`,
  taDesc: `You have entered too many wrong attempts, you can't try anymore`,
  errDesc: `There have been an error, please try again`,
  ok: `Ok`,
  dir: `LTR`,
  verComp: `Verification Completed Successfully`,
  tryAgain: `Try Again`,
  remainingAttemps: `Remaining Attempts: `,
  unhcrWillContact: `UNHCR will contact you in the next few days`,
  noCard: `Thank you for completing the validation and reporting having your card missing. UNHCR will contact you in the next few days`,
  goBack: `Go Back`,
};
